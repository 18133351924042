<template>
  <label class="kw-radio__label">
    <input
      :checked="checked"
      class="hidden kw-radio__input"
      :name="name"
      type="radio"
      :value="value"
      @change="change"
    >
    <span class="kw-radio" :class="{ 'kw-radio__checked': checked }">
      <span />
    </span>
    <slot />
  </label>
</template>

<script setup lang="ts">
defineProps({
  value: {
    type: [String, Number],
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  checked: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['change']);

const change = (e: Event) => {
  emit('change', (e.target as HTMLInputElement).value);
};
</script>

<style scoped lang="scss">
.kw-radio {
  @apply flex border-1 border-icon-light border-solid rounded-full w-5 h-5 items-center justify-center p-1;
  &__label {
    @apply flex items-center gap-3 text-base;
  }

  & > span {
    @apply block rounded-full w-full h-full;
  }

  &__input:checked + &.kw-radio {
    @apply border-primary border-6;
    & > span {
      @apply bg-white;
    }
  }

}
</style>
