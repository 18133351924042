import titles from './titles';
import buttons from './buttons';
import tips from './tips';

export default {
  titles,
  buttons,
  tips,
  assignments: {
    assignments_day: 'Задание дня',
  },
  awards: 'Награды',
  no_awards: 'У вас нет наград',
  get_awards: 'Выполнятей задание, и получай награды',
  auth: 'Авторизация в MathCombat',
  sign_in: 'Войти',
  sign_up: 'Регистрация',
  forgot_password: 'Забыли пароль?',
  name: 'Имя',
  enter_name: 'Введите имя',
  surname: 'Фамилия',
  enter_surname: 'Введите фамилию',
  iin: 'ИИН',
  enter_iin: 'Введите ИИН',
  email: 'Почта',
  enter_email: 'Введите Почту',
  password: 'Пароль',
  enter_password: 'Введите Пароль',
  password_confirmation: 'Повторите пароль',
  change: 'Изменить',
  save: 'Сохранить',
  cancel: 'Отменить',
  accept: 'Принять',
  close: 'Закрыть',
  battle: {
    wins: 'Побед',
    congratulations: 'Поздравляем Вас!',
    you_win: ' Вы победили в матбою!',
    you_win_description: 'Это было не так и просто победить в матбою. Возвращайтесь в Лобби, чтобы найти себе нового соперника!',
    you_lose: 'К сожалению, Вы проиграли в матбою!',
    you_lose_description: 'Не отчаивайтесь! Вы ещё обязательно победите в другом бою! Возвращайтесь в Лобби, чтобы найти себе нового соперника!',
    earned_points: 'Заработали баллы',
    earned_coins: 'Заработали монет',
    choose_answer: 'Выбрать ответ',
    round: 'Раунд',
    your_score: 'Ваши баллы',
    opponent: 'Соперник',
    opponent_score: 'Баллы соперника',
    game_type: 'Тип игры',
    no_battles: 'Нажмите Поиск боя что бы отобразить историю, и предлагаю какую то отдельную страницу со всеми его играми',
    search: 'Поиск...',
    search_opponent: 'Поиск противника',
    search_right_opponent: 'Подбираем подходящего соперника',
    game_ready: 'Ваша игра готова',
    duel_one_to_one: '“Дуэль 1 на 1”',
  },
  back_to_home: 'Вернуться на главную',
  profile: {
    edit: 'Редактирование профиля',
    main: 'Основная информация профиля',
  },
  character: {
    on: 'Надето',
    get_on: 'Надеть',
    buy: 'Купить',
    bought: 'Куплен',
    you_bought: 'Вы купили вещь',
  },
  inventory: {
    title: 'Инвентарь',
  },
  rating: {
    title: 'Рейтинг',
    your_rating: 'Ваш рейтинг',
    your_rank: 'Ваш ранг',
    points: 'Очки рейтинга',
    solved: 'Решил задач',
    place_in_league: 'Место в лиге',
    place: 'Место',
    your_league: 'Ваша лига',
  },
  theme: {
    light: 'Светлая тема',
    dark: 'Темная тема',
  },
  score: 'Баллы',
};
