import type { RouteRecordRaw, Router } from 'vue-router';

const moduleRoute: RouteRecordRaw = {
  path: '/auth',
  name: 'auth',
  component: () => import('@/shared/layouts/TheLayoutAuth.vue'),
  redirect: { name: 'Auth.Login' },
  children: [
    {
      path: 'login',
      name: 'Auth.Login',
      component: () => import('@/modules/auth/pages/Login.vue'),
    },
    {
      path: 'register',
      name: 'Auth.Register',
      component: () => import('@/modules/auth/pages/Register.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
