import type { Axios, AxiosError, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { onError } from '@/shared/network/error-handler';

export interface IHttpParams extends AxiosRequestConfig {}
export interface IHttpResponse extends AxiosResponse {}
export interface IHttpClient {
  get: (url: string, config?: AxiosRequestConfig) => Promise<IHttpResponse>
  post: (url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) => Promise<IHttpResponse>
  put: (url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) => Promise<IHttpResponse>
  patch: (url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) => Promise<IHttpResponse>
  delete: (url: string, config?: AxiosRequestConfig) => Promise<IHttpResponse>
  fakeGet: (data: any) => Promise<IHttpResponse>
}

export default class HttpClient implements IHttpClient {
  constructor(private $http: Axios) {
    this._initializeInterceptors();
  }

  private _initializeInterceptors() {
    this.$http.interceptors.request.use(HttpClient._handleRequest, this._handleRequestError);
    this.$http.interceptors.response.use(this._handleResponse, HttpClient._handleResponseError.bind(this));
  }

  private static _handleRequest(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
    const token = localStorage.getItem('access_token');
    if (config.headers && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }

  private _handleRequestError = (error: AxiosError) => {
    if (error.response) {
      onError(error.response);
    }
    return Promise.reject(error);
  };

  private _handleResponse = (response: AxiosResponse) => response.data;

  private static _handleResponseError = (error: AxiosError) => {
    if (error.response) {
      onError(error.response);
    }
    return Promise.reject(error);
  };

  async get(url: string, config?: AxiosRequestConfig) {
    try {
      return await this.$http.get(url, config);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async post(url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) {
    try {
      return await this.$http.post(url, params, config);
    } catch (e: any) {
      return Promise.reject(e.response.data);
    }
  }

  async put(url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) {
    try {
      return await this.$http.put(url, params, config);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async patch(url: string, params: IHttpParams['params'], config?: AxiosRequestConfig) {
    try {
      return await this.$http.patch(url, params, config);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    try {
      return await this.$http.delete(url, config);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  fakeGet<T>(data: any): Promise<T> {
    return new Promise<T>((resolve) => {
      resolve(data);
    });
  }
}
