export default {
  create_battle: 'Жаңа ШАЙҚАС!',
  duel: 'Жекпе-жек',
  rating: 'Рейтинг',
  assignments: 'Тапсырма',
  assignments_day: 'Бүгінгі тапсырмалар',
  games_history: 'Ойындар тарихы',
  mine_statistic: 'Менің статистикам',
  current_league: 'Ағымдағы лига',
  rating_points: 'Рейтинг ұпайлары',
  inventory: 'Жабдықтар',
  profile: 'Профиль',
  type_of_game: 'Ойын түрі',
  training: 'Жаттығу',
  wins: 'Ұтыстар',
  count_of_games: 'Ойын саны',
  watch: 'Қарау',
  math_battle: 'Математикалық шайқас',
};
