import type { App } from 'vue';
import LazyLoadImage from 'shared/components/base/LazyLoadImage.vue';
import KWHeading from '@/shared/components/base/KWHeading.vue';
import KWInput from '@/shared/components/base/KWInput.vue';
import KWButton from '@/shared/components/base/KWButton.vue';
import KWTabs from '@/shared/components/base/KWTabs.vue';
import KWCheckbox from '@/shared/components/base/KWCheckbox.vue';
import KWRadio from '@/shared/components/base/KWRadio.vue';
import KWBadge from '@/shared/components/base/KWBadge.vue';
import KWText from '@/shared/components/base/KWText.vue';

export function registerGlobalComponents(app: App) {
  // https://youtrack.jetbrains.com/issue/WEB-48239
  const Vue = app; // For PHPStorm/WebStorm only hack to recognize global components

  Vue.component('KWHeading', KWHeading);
  Vue.component('KWInput', KWInput);
  Vue.component('KWButton', KWButton);
  Vue.component('KWTabs', KWTabs);
  Vue.component('KWCheckbox', KWCheckbox);
  Vue.component('KWRadio', KWRadio);
  Vue.component('KWBadge', KWBadge);
  Vue.component('KWText', KWText);
  Vue.component('LazyLoadImage', LazyLoadImage);
}
