<template>
  <div
    ref="container"
    class="w-full relative isolate h-full block"
    :class="containerClass"
  >
    <img
      ref="image"
      :alt="alt"
      class="object-cover transition-opacity duration-300 w-inherit h-inherit pointer-events-none"
      :class="[attrs.class, isVisible ? 'opacity-100' : 'opacity-0']"
      src=""
    >
    <span
      v-if="!isVisible"
      class="w-full h-full block absolute left-0 top-0 bottom-0 bg-divider-light bg-opacity-40 rounded-large right-0 skeleton-1-delay"
      :class="skeletonClass"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useEventListener, useIntersectionObserver } from '@vueuse/core';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  containerClass: {
    type: String,
    default: '',
  },
  skeletonClass: {
    type: String,
    default: '',
  },
});
const attrs = useAttrs();
const image = ref<HTMLImageElement>();
const isVisible = ref(!props.src);

const { stop } = useIntersectionObserver(
  image,
  ([{ isIntersecting }]) => {
    if (isIntersecting && props.src) {
      image.value!.src = props.src;
      useEventListener(image, 'load', () => {
        isVisible.value = true;
        stop();
      }, { once: true });
    }
  }, {
    threshold: 0.1,
  },
);
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
