<template>
  <div class="rounded-full p-1 gap-2 flex items-center w-full bg-bg-overlay-light justify-around">
    <button
      v-for="tab in tabList"
      :key="tab.id"
      class="py-2 rounded-full w-full text-body-large duration-200 transition-colors leading-title-large"
      :class="tab.id === currentTab.id ? 'bg-white' : 'bg-transparent'"
      @click="emit('change', tab)"
    >
      {{ tab.name }}
    </button>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { ITabsItem } from '@/shared/types/common';

defineProps({
  tabList: {
    type: Array as PropType<ITabsItem[]>,
    required: true,
  },
  currentTab: {
    type: Object as PropType<ITabsItem>,
    required: true,
  },
});
const emit = defineEmits(['change']);
</script>
