<template>
  <label
    class="flex items-center cursor-pointer h-fit w-fit"
    :class="[wrapperClass]"
    :for="id"
  >
    <input
      v-bind="$attrs"
      :id="id"
      :checked="checked"
      class="checkbox__input"
      type="checkbox"
      :value="checked"
      @change="onChange"
    >
    <span class="checkbox" :class="{ 'checkbox--checked': checked }">
      checked
    </span>
    <slot />
  </label>
</template>

<script lang="ts" setup>

defineProps({
  /**
   * ID of the checkbox
   */
  id: {
    type: String,
    required: true,
  },
  /**
   * Value of the checkbox
   * @model
   */
  checked: {
    type: Boolean,
    default: false,
  },
  wrapperClass: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['update:checked']);

function onChange(e: Event) {
  emit('update:checked', (e.target as HTMLInputElement).checked);
}
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style lang="scss" scoped>
.checkbox {
  @apply bg-white border-solid rounded-small flex border-icon-light border-1 h-6 mr-3 w-6  relative items-center justify-center;

  &--checked {
    @apply bg-primary text-white border-primary;
  }

  &__input {
    @apply h-full w-full hidden;
    &:disabled + .checkbox {
      @apply bg-gray-100;
    }
  }
}
</style>
