import type { Router } from 'vue-router';
import middlewarePipeline from '@/app/middleware/middlewarePipeline';

export function setupRouterGuards(router: Router) {
  router.beforeEach(async (to, from, next) => {

    if (!to.meta.middleware) {
      return next();
    }

    const middleware: any = to.meta.middleware;
    const context = {
      to,
      from,
      next,
    };

    return middleware[0]({
      ...context,
      nextMiddleware: middlewarePipeline(context, middleware, 1),
    });
  });
}

