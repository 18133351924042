<template>
  <div>
    <label
      class="text-field-text-inactive text-base"
      :class="{
        '!text-field-text-error': isError,
      }"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="relative mt-2">
      <input
        v-bind="$attrs"
        :id="id"
        ref="target"
        class="w-full text-field-text-active border border-solid border-field-stroke-inactive border-opacity-25 rounded-2.5 p-4 placeholder:text-field-text-inactive"
        :class="{
          '!border-field-stroke-active !bg-field-background-active': focused && !isError,
          '!border-field-stroke-error !bg-field-background-error': isError,
        }"
        :disabled="disabled"
        :placeholder="placeholder"
        :type="type"
        :value="modelValue"
        @input="onInput"
      >
      <div class="absolute top-4.5 right-4">
        <slot name="icon" />
      </div>
    </div>
    <p
      class="transition-all transition-ease duration-300 text-field-text-error text-[14px]"
      :class="!isError ? 'h-0 opacity-0 p-0' : 'h-auto opacity-100 pt-3'"
    >
      <!-- @slot slot for input error message -->
      <slot name="error" />
    </p>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { useFocus } from '@vueuse/core';

type inputVariant = 'number' | 'text' | 'password' | 'email';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  isError: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<inputVariant>,
    default: 'text',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
const target = ref();
const { focused } = useFocus(target);
const onInput = (e: Event) => {
  let value: string | number = (e.target as HTMLInputElement).value;
  value = props.type === 'number' && value ? Number(value) : value;
  emit('update:modelValue', value);
};
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<style scoped lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
