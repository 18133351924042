import { defineStore } from 'pinia';
import { store } from '@/shared/stores';

const STORE_ID = 'auth';

export const useAuthStore = defineStore(STORE_ID, {
  getters: {
    token: () => localStorage.getItem('access_token'),
    isLoggedIn: () => localStorage.getItem('access_token') !== null,
  },
  actions: {
    login(token: string) {
      localStorage.setItem('access_token', token);
    },
    logout() {
      localStorage.removeItem('access_token');
    },
  },
  persist: {
    key: STORE_ID,
  },
});

export function useAuthStoreOutside() {
  return useAuthStore(store);
}
