import type { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';

export function onError(error: AxiosResponse['data']) {
  // Обрабатываем ошибки, может быть отправляем в Sentry
  console.log(error);
  Sentry.withScope((scope) => {
    const headers = error.config?.headers;
    if (headers?.Authorization) {
      delete headers.Authorization;
    }
    const message = error.data?.error || error.data?.message || '';
    const name = `API Error: ${error.config.url}`;

    scope.setLevel('error');

    scope.addBreadcrumb({
      category: 'data',
      level: 'info',
      message: JSON.stringify(
        {
          url: error.config.url,
          headers,
          params: error.config.params,
          request: error.config.data || null,
          response: error.data || null,
        },
        null,
        3,
      ),
    });

    const errors = new Error(message);
    errors.name = name;
    Sentry.captureException(errors);
  });
  return error;
}
