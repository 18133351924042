import type { App } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import auth from '@/app/middleware/auth';

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      middleware: [auth],
    },
    component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
    redirect: { name: 'Home.Main' },
    children: [
      {
        path: '',
        name: 'Home.Main',
        component: () => import('@/modules/home/pages/Main.vue'),
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
});

export function setupRouter(app: App) {
  app.use(router);
}
