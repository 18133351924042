import type { App } from 'vue';
import * as Sentry from '@sentry/vue';
import { router } from '../router';

export function initSentry(app: App) {
  Sentry.init({
    app,
    dsn: 'https://b818ad3eb3541acd66c9b50fa4d4523d@o4508096778207232.ingest.de.sentry.io/4508096779780176',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/matkombat\.kz\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
