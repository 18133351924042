export const initMetrika = () => {
  console.log('initMetrika', import.meta.env.PROD);
  if (import.meta.env.PROD) {
    if (!window.ym) {
      window.ym = function () {
        window.ym.a = window.ym.a || [];
        // eslint-disable-next-line prefer-rest-params
        window.ym.a.push(arguments);
      };
    }
    // @ts-ignore
    window.ym.l = 1 * new Date();
    for (let j = 0; j < document.scripts.length; j++) {
      if (document.scripts[j].src === 'https://mc.yandex.ru/metrika/tag.js') {
        return;
      }
    }
    const k = document.createElement('script');
    const a = document.getElementsByTagName('script')[0];
    k.async = true;
    k.src = 'https://mc.yandex.ru/metrika/tag.js';
    a?.parentNode?.insertBefore(k, a);
    if (window.ym) {
      window.ym(import.meta.env.VITE_YA_METRIKA_KEY, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      });
    }
  }
};

export const setYandexUserParams = (userParams: Record<string, string>) => {
  if (window.ym) {
    window.ym(import.meta.env.VITE_YA_METRIKA_KEY, 'userParams', userParams);
  }
};
export const setYandexSessionParams = (sessionParams: Record<string, string>) => {
  if (window.ym) {
    window.ym(import.meta.env.VITE_YA_METRIKA_KEY, 'params', sessionParams);
  }
};
