import { createI18n } from 'vue-i18n';
import ru from './ru/index';
import kk from './kk/index';

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  allowComposition: true,
  messages: {
    ru,
    kk,
  },
  globalInjection: true,
  legacy: false,
});

export default i18n;
