import type { RouteRecordRaw, Router } from 'vue-router';
import auth from '@/app/middleware/auth';

const moduleRoute: RouteRecordRaw = {
  path: '/search-battle',
  name: 'SearchBattle',
  component: () => import('@/shared/layouts/TheLayoutDefault.vue'),
  redirect: { name: 'SearchBattle.Main' },
  meta: {
    middleware: [auth],
  },
  children: [
    {
      path: '',
      name: 'SearchBattle.Main',
      component: () => import('@/modules/search-battle/pages/Main.vue'),
    },
  ],
};

export default (router: Router) => {
  router.addRoute(moduleRoute);
};
